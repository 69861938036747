import React from 'react';
import '../../src/Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>Focus Click Click | 2024 &#169;</p>
    </footer>
  );
};

export default Footer;
