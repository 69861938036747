import React from 'react';
import '../../src/About.css';

const About = () => {
  return (
    <div className='about' style={{ padding: '20px' }}>
      <h2>About Us</h2>
      <p>
        Focus Click Click! is a licensed & insured aerial marketing media company in Mineral Wells, Texas, serving Palo Pinto & the neighboring counties.<br/> We're <em>focused</em> on making life easier for you. 
      </p>
      <p>
        <br/> We believe in the power of visuals to tell a story.<br/> Keep things simple & <strong className='call'>BOOK TODAY & allow us to FLY FOR YOU.</strong>! 
      </p>
      <p>
        Email Us at <span className='aqua'>hello@focusclickclick.com</span>.
      </p>
      <h2>Please include the following:</h2>
      <ul>
        <li>Property location(s)</li>
        <li>Any gate codes or information necessary to gain access into the property</li>
        <li>Date(s) you would like services performed</li>
        <li>Service that you are interested in booking</li>
        <li>Any questions you may have</li>
        <li>If you would like to be present at the property you're more than welcome to join but it is not necessary. We only need to be outside of the property to take the aerial photos.</li>
      </ul>
      
      
    </div>
  );
};

export default About;
